<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <img
            @click="backToFacilityIssues()"
            src="../assets/images/thickbackarrow.svg"
            alt="back"
          />
          <h6 @click="backToFacilityIssues()">Facilities</h6>
        </div>

        <div class="container">
          <div class="search_holder">
            <div class="prop_facility_header">
              <p>Property Facilities</p>
            </div>
            <div class="property_details">
              <div class="days_left">
                <h3>365</h3>
                <h6>Days Left</h6>
              </div>
              <div class="details">
                <div>
                  <img
                    src="../assets/images/verified-white.svg"
                    alt="verified"
                  />
                  <small>Verified</small>
                </div>
                <h2>3 Bedroom Fully Detached Duplex</h2>
                <small>Maitama</small>
              </div>
            </div>

            <div class="body_holder">
              <div class="issue_title">
                <p>Elevator</p>
              </div>
              <div class="sub_body">
                <p class="facility_description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  pharetra, est vel pulvinar auctor, magna purus pellentesque
                  enim, at sagittis mi lectus quis libero. Quisque eu
                  scelerisque eros, non accumsan quam. Morbi sit amet tristique
                  enim. Nunc vel venenatis velit. Aenean aliquet ultrices magna
                  maximus vu
                </p>
                <div class="option">
                  <div class="row">
                    <div class="col-md-4 col-sm-6">
                      <div class="" style="background: '#cae6ff'">
                        <div>
                          <button
                            class="expand_btn btn btn-block"
                            @click="expand = !expand"
                          >
                            Facility Management Options
                            <i
                              class="fa fa-arrow-down "
                              :class="expand ? 'arrow_up' : 'bounce'"
                            ></i>
                          </button>
                        </div>
                        <div class="btn_options" v-if="expand">
                          <button class="btn btn-block">
                            View details
                          </button>

                          <button class="btn btn-block">
                            Report Damage/Malfunction
                          </button>

                          <button class="btn btn-block">
                            View Maintenance Log
                          </button>

                          <button class="btn btn-block">
                            Report Damage/Malfunction
                          </button>

                          <button class="btn btn-block">
                            I need a Vendor
                          </button>

                          <button class="btn btn-block">
                            Report Damage/Malfunction
                          </button>
                        </div>
                      </div>

                      <div class="facility_image">
                        <img
                          src="../assets/images/elevator.svg"
                          alt="facility"
                        />
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-6">
                      <div class="issue_report">
                        <div class="damage_text">
                          <p>Report Damage/Malfunction</p>
                        </div>
                        <form>
                          <div class="form-group">
                            <label for="cause">What caused it?</label>
                            <select
                              name="cause"
                              id="cause"
                              class="form-control input-bar"
                            >
                              <option value="">Please select</option>
                            </select>
                          </div>

                          <div class="form-group">
                            <label for="desc">What caused it?</label>
                            <textarea
                              class="form-control"
                              name="desc"
                              id="desc"
                              cols="30"
                              rows="6"
                            >
                                Please be as clear as possible...</textarea
                            >
                          </div>

                          <div class="form-group">
                            <label for="cause">Priority</label>
                            <select
                              name="priority"
                              id="priority"
                              class="form-control input-bar"
                            >
                              <option value="">Please select</option>
                            </select>
                          </div>

                          <div class="row mt-5">
                            <div class="col-md-5 col-sm-6">
                              <div>
                                <div
                                  class="file_holder"
                                  @click="$refs.facility.click()"
                                  v-if="!issueUpload"
                                >
                                  <img
                                    v-if="!fileUrl"
                                    src="../assets/images/uploadfile.svg"
                                    alt="receipt"
                                  />
                                  <img
                                    v-if="fileUrl"
                                    class="exiting_reciept_img"
                                    :src="fileUrl"
                                    alt="receipt"
                                  />
                                  <input
                                    ref="facility"
                                    type="file"
                                    name="receipt"
                                    id="receipt"
                                    class="d-none"
                                  />
                                  <p v-if="!uplaodingData">
                                    Click here to upload an image
                                  </p>
                                  <h6
                                    class="uploading_text"
                                    v-if="uplaodingData"
                                  >
                                    Uploading please wait...
                                  </h6>
                                </div>
                                <div v-else class="file-holder">
                                  <div
                                    class="file_holder file_uploaded_successfully"
                                    @click="$refs.facility.click()"
                                  >
                                    <img
                                      src="../assets/images/check-uploaded.svg"
                                      alt="file"
                                    />
                                    <input
                                      ref="facility"
                                      type="file"
                                      name="receipt"
                                      id="receipt"
                                      class="d-none"
                                    />
                                    <p>
                                      File uploaded successfully
                                    </p>
                                  </div>
                                </div>
                                <div class="file_format">
                                  <h6>File formats PNG, JPEG or PDF</h6>
                                  <p v-if="uplaodingData">{{ reciptimage }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-7 col-sm-6">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  I hereby declare that the information
                                  presented therein are true and correct and
                                  that I will be liable for any false
                                  information given thereof.
                                </label>
                              </div>
                              <div class="btn_submit">
                                <button class="btn">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
import moment from "moment";
const Api = new Service();
export default {
  name: "FacilityCheck",
  components: {
    SmallNav
    // PageLoader,
  },
  data() {
    return {
      PageLoad: false,
      detailsProps: "",
      issueUpload: false,
      fileUrl: "",
      uplaodingData: false,
      expand: false
    };
  },
  mounted() {
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.detailsProps = Api.getPropertyDetails();
    console.log("PropId", JSON.stringify(this.detailsProps.id));
    // if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
    //   this.$router.push({
    //     path: `/dashboard/rented`,
    //   });
    // }
  },
  methods: {
    backToFacilityIssues() {
      this.$router.push({
        path: `/dashboard/facilityissues`
      });
    }
  },
  // computed: {
  //   getdata() {
  //     return this.this.fetchPropertySchdeule();
  //   }
  // },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
    // moment: function(date) {
    //   return moment(date).format("MMMM DD YYYY, h:mm:ss a");
    // },
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}
.search_holder {
  background: $secondary;
  border-radius: 10px;
  margin: auto;
  max-width: 992px;
  width: 100%;
}
.properties_count {
  text-align: left;
  padding: 30px 20px;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
    cursor: pointer;
  }
}

.prop_facility_header {
  padding: 19px 26px;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0 0;
  //  transform: matrix(1, 0, 0, -1, 0, 0);
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $secondary;
    margin-bottom: 0;
  }
}

.days_left {
  width: 67px;
  height: 67px;
  //   position: absolute;
  position: relative;
  background: $secondary;
  border-radius: 50%;
  padding-top: 18px;
  margin: 7px;
  border: 1px solid #768fe9;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    color: $primary;
  }
}

.property_details {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  display: grid;
  grid-template-columns: 10% 1fr;
  column-gap: 5px;
  padding: 15px;
}

.details {
  text-align: left;
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    // display: flex;
    align-items: center;
    color: $secondary;
    margin-left: 10px;
  }
  h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 18px;
    // display: flex;
    align-items: center;
    color: $secondary;
    margin-top: 10px;
  }
}

.issue_title {
  background: #cae6ff;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom: 0;
  }
}

.body_holder {
  .issue_title {
    max-width: 908px;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
      padding: 23px;
    }
  }
}

.facility_description {
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: $black;
}

.sub_body {
  margin: 23px 40px 40px 40px;
  text-align: left;
}

.expand_btn {
  padding-top: 20px;
  padding-bottom: 15px;
  background: #cae6ff;
  border: none;
  border-radius: 0;
  outline: 0;
  color: $black;
  box-shadow: none;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 30px;
  font-family: Gotham;
  i {
    padding-left: 30px;
  }
}

.arrow_up {
  transform: rotate(180deg);
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.bounce {
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.btn_options {
  padding: 0 20px 30px;
  display: block;
  background: #cae6ff;
  button {
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 30px;
    align-items: center;
    color: $secondary;
    outline: 0;
    box-shadow: none;
  }
  button:first-child {
    background: $primary;
    color: $secondary;
  }
  button:nth-child(2) {
    background: #ffb100;
    color: $secondary;
  }
  button:nth-child(3) {
    background: rgba(0, 0, 0, 0.65);
    color: $secondary;
  }
  button:nth-child(4) {
    background: #d5a8a1;
    color: $secondary;
  }
  button:nth-child(5) {
    background: #2fc643;
    color: $secondary;
  }
  button:nth-child(6) {
    background: #8c52a2;
    color: $secondary;
  }
}

.facility_image {
  margin-top: 20px;
  img {
    width: 100%;
  }
}

.input-bar {
  background: $secondary;
  height: 36px;
  border: 0.5px solid #cecece;
  // border: 0.5px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;
  padding: 20px 15px;
}

label {
  font-family: Gotham;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 30px;
  // align-items: center;
  color: $black;
}

.forward_arrow {
  display: none;
}

.issue_report {
  border: 1px solid #cecece;
  padding: 30px 30px 20px 30px;
  margin-bottom: 40px;
}

.damage_text {
  border-bottom: 1px solid #cecece;
  margin-bottom: 30px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom: 0;
  }
}

.file_holder {
  padding: 36px 20px;
  background: $secondary;
  box-shadow: 10px 10px 30px rgb(0 0 0 / 10%);
  text-align: center;
  cursor: pointer;
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.9);
  }
  h6:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    color: #ff9c2a;
  }

  .file_uploaded_successfully {
    padding-top: 3.5rem !important;
    p:nth-child(3) {
      color: #28a745 !important;
    }
  }
  // .upload-file-holder {
  //   margin: 3rem auto;
  //   margin-bottom: 1.5rem;
  //   width: 285px;
  //   height: 202px;
  //   background: #ffffff;
  //   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  // }

  .uploading_text {
    animation: blinker 1s linear infinite;
  }

  .exiting_reciept_img {
    width: 100%;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
.rent_receipt {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 25px;
}

.file_format {
  padding-top: 20px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    // display: flex;
    align-items: center;
    color: #b93623;
    text-align: center;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    // display: flex;
    align-items: center;
    color: #2fc643;
  }
}

.btn_submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  button {
    color: $secondary;
    background: #2fc643;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 599px) {
  .property_details {
    grid-template-columns: 24% 1fr;
  }
  .details {
    h2 {
      line-height: 25px;
    }
  }
  .days_left {
    width: 55px;
    height: 55px;
    padding-top: 10px;
    h3 {
      font-size: 13px;
    }
    h6 {
      font-size: 9px;
    }
  }
  .facility_description {
    margin-bottom: 30px;
  }
  // .btn_options {
  //   // padding: 0;
  //   padding: 0 20px 10px;
  // }
  .sub_body {
    margin: 23px 15px 40px 15px;
    text-align: justify;
  }
  .issue_report {
    margin-top: 30px;
    padding: 30px 15px 20px 15px;
  }
}
</style>
